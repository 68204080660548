.last-col {
	width: 1%;
}

.item-avatar {
	height: 100% !important;
	max-width: 40px !important;
	max-height: 40px !important;
}

.time {
	padding: 0%;
	/* font-size: 15px; */
}

.text-box-left {
	font-size: 15px;
	letter-spacing: 0.2px;
	line-height: 20px;
	margin: 2px;
	border-radius: 15px;
	float: left;
}

.text-box-right {
	font-size: 15px;
	letter-spacing: 0.2px;
	line-height: 20px;
	margin: 2px;
	border-radius: 15px;
	float: right;
	background-color: #00b96b;
	color: white;
}

.text-label {
	color: #4c4a37;
	font-weight: bold;
}

p {
	margin: 10px;
	/* font-weight: bold; */
}
