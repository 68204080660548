:root {
	--ion-safe-area-top: 20px;
}
.search {
	color: #ffffffcc;
	background-color: #4d6381;
	float: center;
	margin-left: 2px;
	border-radius: 20px;
	max-width: 98%;
	height: 30px;
}

.searchbar {
	letter-spacing: 0.2px;
	color: #ffffffcc;
	background-color: #263f5f;
	margin: 0px;
}
