.title {
	padding-top: 10px;
	text-align: center;
}

.button {
	padding: 8px;
	text-align: center;
	float: right;
}
