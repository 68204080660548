.footer {
	max-height: 100%;
}
.toolbar {
	letter-spacing: 0.2px;
	color: #ffffffcc;
	background-color: #263f5f;
	margin: 0;
	padding: 0;
}
.item {
	padding: 0;
}
.input {
	text-overflow: clip;
	color: #ffffffcc;
	background-color: #4d6381;
	height: 38px;
	margin: 12px;
	border-radius: 14px;
}
.button {
	margin: 0;
	padding: 0;
}
