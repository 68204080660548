:root {
	--ion-safe-area-top: 20px;
}

.button {
	height: 100%;
	max-width: 80px;
	--padding-start: 12px;
	--padding-end: 12px;
}
